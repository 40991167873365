// Store
import store from "@/store";

export interface AppArticleCreateIndex {
  organization_id: string | string[];
  unit_of_measure_id: string | string[];
  content: {
    title: string;
  }
}

export class AppArticleCreateIndex implements AppArticleCreateIndex {
  organization_id: string | string[];
  unit_of_measure_id: string | string[];
  content: {
    title: string;
  }

  constructor() {
    this.organization_id = store.getters.getOrganizationMember.organization_id ?? "";
    this.unit_of_measure_id = "";
    this.content = {
      title: "",
    };
  }
}