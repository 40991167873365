
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AdministratorNavigationVue from "@/components/administrator/navigation.vue";
import AdministratorNavigationMasterDataVue from "@/components/administrator/navigation-master-data.vue";
import AppAdministratorMasterDataArticlesListIndexVue from "@/views/app/administrator/master-data/articles/list/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    AdministratorNavigationVue,
    AdministratorNavigationMasterDataVue,
    AppAdministratorMasterDataArticlesListIndexVue,
  },
})
export default class AppAdministratorMasterDataArticlesIndexVue extends Vue {}
