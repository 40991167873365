// Models
import {AppUnitOfMeasureIndex} from "@/models/app/unit-of-measure";

export interface AppArticleIndex {
  id: string;
  organization_id: string | string[];
  unit_of_measure_id: string | string[];
  content: {
    title: string;
  }
  created_at: string;
  updated_at: string;
  unit_of_measure: AppUnitOfMeasureIndex;
}

export class AppArticleIndex implements AppArticleIndex {
  id: string;
  organization_id: string | string[];
  unit_of_measure_id: string | string[];
  content: {
    title: string;
  }
  created_at: string;
  updated_at: string;
  unit_of_measure: AppUnitOfMeasureIndex;

  constructor() {
    this.id = "";
    this.organization_id = "";
    this.unit_of_measure_id = "";
    this.content = {
      title: "",
    };
    this.created_at = "";
    this.updated_at = "";
    this.unit_of_measure = new AppUnitOfMeasureIndex();
  }
}